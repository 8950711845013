<template>
  <main class="site-main">
    <div v-if="painting && painting.image" className="painting">
      <h1>{{ painting.title }}</h1>

      <SanityImage :image="painting.image" :alt="painting.alt" fit="fill" />
      <p>
        <span v-if="painting.hight && painting.width"
          >{{ painting.width }}cm x {{ painting.hight }}cm</span
        ><br />
        <span v-if="painting.price">€{{ painting.price }}</span>
      </p>
      <div class="back-btn">
        <router-link to="/" class="button">
          <FontAwesomeIcon :icon="icon" /> Zurück</router-link
        >
      </div>
    </div>
  </main>
</template>
<script>
import { ref, onMounted } from "vue";
import useSanity from "../use/useSanity";
import { useRoute } from "vue-router";
import SanityImage from "../components/SanityImage.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default {
  components: { SanityImage, FontAwesomeIcon },
  data: () => {
    return { icon: faArrowLeft };
  },
  setup() {
    const route = useRoute();
    const { getPainting } = useSanity();
    const painting = ref([]);

    onMounted(async () => {
      painting.value = await getPainting(route.params.key);
    });

    return { painting };
  },
};
</script>
<style lang="scss" scoped>
main {
  padding: 0;
  width: 100%;
}
.painting {
  margin: 2rem auto 0;
  max-width: 80%;
  text-align: center;
  img {
    max-height: 80vh;
  }
  @media only screen and (max-width: 450px) {
    margin-top: 5rem;
    width: 100%;
    max-width: 100%;
    img {
      max-height: 90vh;
    }
  }
  .back-btn {
    margin-top: 1rem;
  }
}
</style>
